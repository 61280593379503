<template>
  <div>
    <list-layout ref="listLayout"
                 :filter-form="filterForm"
                 :thead="thead"
                 :on-fetch="getList"
                 :on-delete="deleteItem"
                 :on-toggle="toggleItem"
                 :on-update="updateItem"
                 @command="handleOperation"
    >
      <template #tabletop>
        <el-button
            type="primary"
            size="small"
            @click="showAddCategory = true"
        >添加</el-button
        >
        <el-button type="danger" size="small" @click="onDelete">删除</el-button>
      </template>
      <AddCategory :showAddCategory="showAddCategory" :editObj="editObj" @closeAddDialog="closeAddDialog"
                   @updateData="updateData" />
      <CategoryTransfer :showTransfer="showTransfer" :transferId="transferId"
                        @closeTransfer="showTransfer = false" @transferSuccess="transferSuccess" :updateList="refreshList">
      </CategoryTransfer>
    </list-layout>
  </div>
</template>

<script>
import ListLayout from "@/base/layout/ListLayout";
import AddCategory from "@/modules/approval/components/category-list/AddCategory";
import CategoryTransfer from "@/modules/approval/components/category-list/CategoryTransfer";
import {
  deleteCategory,
  getCategoryList,
  updateCategoryShow,
  updateCategorySort
} from "@/modules/approval/api/category-list";

export default {
  components: {ListLayout, AddCategory, CategoryTransfer},
  data() {
    return {
      filterForm: {},
      thead: [
        {
          type: 'selection'
        },
        {
          label: '排序',
          prop: 'sort'
        },
        {
          label: 'ID',
          prop: 'id'
        },
        {
          label: '名称',
          prop: 'name'
        },
        {
          label: '数量',
          prop: 'tmpls_count',
          sortable: true
        },
        {
          label: '显示',
          prop: 'is_show',
          type: 'checkbox'
        },
        {
          label: '操作',
          type: 'operation',
          width: 150,
          operations: [
            {command: 'edit', text: '编辑'},
            {command: 'move', text: '转移'},
            {command: 'delete'},
          ]
        }
      ],
      validator: {
        type: "integer",
        min: 1,
        message: "最小不能低于1的整数",
        trigger: "blur",
      },
      showAddCategory: false,
      editObj: {},
      showTransfer: false,
      transferId: "",
    }
  },
  methods: {
    getList(q = {}) {
      return getCategoryList({...this.filterForm, ...q})
    },
    // 刷新列表
    refreshList() {
      this.$refs.listLayout.getList();
    },
    updateItem(data) {
      return updateCategorySort(data)
    },
    toggleItem(data) {
      return updateCategoryShow(data)
    },
    deleteItem(data) {
      return deleteCategory(data)
    },
    updateData() {
      this.showAddCategory = false;
      this.refreshList();
    },
    closeAddDialog() {
      this.showAddCategory = false;
      this.editObj = {};
    },
    transferSuccess() {
      this.showTransfer = false;
      this.transferId = 0;
    },
    handleTransfer(id) {
      this.transferId = id;
      this.showTransfer = true;
    },
    onDelete() {
      this.$refs.listLayout.deleteSelected()
    },
    handleOperation(e) {
      switch(e.command) {
        case 'edit': {
          this.editObj = e.row
          this.showAddCategory = true
          break
        }
        case 'move': {
          this.handleTransfer(e.row.id)
          break
        }
        default:
      }
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
